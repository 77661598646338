import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout/layout";

import PageContainer from "../../components/layout/page-container";
import PageSection from "../../components/layout/page-section";
import FilterBar from "../../components/browser/filter-bar";

class PerformancesByVenuePage extends React.Component {
  constructor({ data }) {
    super();

    this.data = data;
  }

  render() {
    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <PageSection background="dark-accent">
            <h1 className="page-title">Performances by Venue</h1>
          </PageSection>
          <PageSection topPadding="md">
            <FilterBar filterSet="performances" currentFilter="venue" />
            <ul className="listing-grid">
              {this.data.allVenuesJson.edges.map(({ node }) => (
                <li key={node.reference} className="listing-grid__cell">
                  <Link to={`/performances/venue/${node.reference}`}>{node.canonical_name}</Link>
                </li>))}
            </ul>
            <FilterBar filterSet="performances" currentFilter="venue" />
          </PageSection>
        </PageContainer>
      </Layout>
    );
  }
}

export default PerformancesByVenuePage;

export const query = graphql`
  query PerformancesByVenueQuery {
    allVenuesJson (sort: {fields: canonical_name}) {
      edges {
        node {
          reference
          canonical_name
        }
      }
    }
  }
`